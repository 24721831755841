import React from "react"
import { Box, Text } from "theme-ui"
import SEO from "../../components/SEO"
import Layout from "../../components/Layout"
import PageHeader from "../../components/PageHeader"
import ContentSection from "../../components/ContentSection"

const Tnpsc = () => {
  return (
    <Box>
      <SEO title="Tnpsc study materials" />
      <Layout>
        <PageHeader>
          <Text
            sx={{ fontSize: [3, 5], fontWeight: "bold", textAlign: "center" }}
          >
            TNPSC
          </Text>
        </PageHeader>
        <ContentSection
          header="TNPSC"
          body={
            <Box
              sx={{
                maxWidth: "800px",
                marginTop: ["-50px", "auto"],
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "auto",
              }}
            >
              <Text sx={{ fontSize: [0, 3], textAlign: "justify" }}>
                The Tamil Nadu Public Service Commission (TNPSC) exams are
                conducted for recruitment to various government positions in
                Tamil Nadu. Eligibility criteria vary from different exams, but
                generally, candidates must be Indian citizens and meet age,
                educational, and other specific requirements.
              </Text>

              <Text sx={{ fontSize: [0, 3], textAlign: "justify" }} my={4}>
                1.{" "}
                <span style={{ fontWeight: "800" }}>
                  Government Job Opportunities:{" "}
                </span>
                TNPSC exams open doors to various government jobs in Tamil Nadu,
                Providing stability and security.
              </Text>

              <Text sx={{ fontSize: [0, 3], textAlign: "justify" }} mt={4}>
                2.{" "}
                <span style={{ fontWeight: "800" }}>
                  Diverse Job Profiles:{" "}
                </span>{" "}
                TNPSC offers a range of job opportunities across different
                departments, such as administrative services, police, education,
                and more.
              </Text>
              <Text sx={{ fontSize: [0, 3], textAlign: "justify" }} mt={4}>
                3. <span style={{ fontWeight: "800" }}>Career Growth: </span>
                Successful candidates can experience career progression through
                promotions and increments in the government sector.
              </Text>
              <Text sx={{ fontSize: [0, 3], textAlign: "justify" }} mt={4}>
                4. <span style={{ fontWeight: "800" }}>Job Security:</span> A
                Government jobs are known for their stability and job security,
                which can be reassuring for candidates.
              </Text>
              <Text sx={{ fontSize: [0, 3], textAlign: "justify" }} mt={4}>
                5.{" "}
                <span style={{ fontWeight: "800" }}>
                  Good Salary and Perks:
                </span>{" "}
                TNPSC positions often come with competitive salaries,
                allowances, and benefits.
              </Text>
              <Text sx={{ fontSize: [0, 3], textAlign: "justify" }} mt={4}>
                6. <span style={{ fontWeight: "800" }}>Social Impact:</span>{" "}
                Working in government roles allows individuals to contribute to
                the development and well-being of society
              </Text>
              <Text sx={{ fontSize: [0, 3], textAlign: "justify" }} mt={4}>
                7. <span style={{ fontWeight: "800" }}>Prestige: </span>{" "}
                Government jobs are often considered prestigious, and
                individuals holding such positions are respected in the
                community.
              </Text>
              <Text sx={{ fontSize: [0, 3], textAlign: "justify" }} mt={4}>
                8.{" "}
                <span style={{ fontWeight: "800" }}>
                  Opportunities for Skill Development:{" "}
                </span>{" "}
                Government Jobs provide opportunities for continuous learning
                and skill development
              </Text>
            </Box>
          }
        />
      </Layout>
    </Box>
  )
}
export default Tnpsc
